import axios from 'axios';
import { CategoryScale, Chart as ChartJS, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import './App.css';
import useAnnotations from './useAnnotations';
import useCanvas from './useCanvas';

const nameMap = {
  'workdays': 'Vardagar',
  'weekend': 'Helger',
  'monday': 'Måndagar',
  'tuesday': 'Tisdagar',
  'wednesday': 'Onsdagar',
  'thursday': 'Torsdagar',
  'friday': 'Fredagar',
  'saturday': 'Lördagar',
  'sunday': 'Söndagar',
}
const colorMap = {
  'workdays': '#FF9933',
  'weekend': '#33DD33',
  'monday': '#CC3333',
  'tuesday': '#CCFF99',
  'wednesday': '#3399CC',
  'thursday': '#9933CC',
  'friday': '#77AA66',
  'saturday': '#3344BB',
  'sunday': '#DD33AA',
}
const hiddenMap = {
  'workdays': false,
  'weekend': false,
  'monday': true,
  'tuesday': true,
  'wednesday': true,
  'thursday': true,
  'friday': true,
  'saturday': true,
  'sunday': true,
}

const App = () => {
  const [serverData, setServerData] = useState();
  const [chartData, setChartData] = useState();
  const [currentVisitors, setCurrentVisitors] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/stats');
      if (response.status < 300) {
        setServerData(response.data);
      }

      const visitorsResponse = await axios.get('visitors');
      if (visitorsResponse.status < 300) {
        setCurrentVisitors(visitorsResponse.data.visitors);
      }
    }
    fetchData();

    const timeoutId = setInterval(fetchData, 60_000);
    return () => clearInterval(timeoutId);
  }, []);

  useEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      LineElement,
      PointElement,
      Legend,
      Title,
      Tooltip,
      AnnotationPlugin,
    );
  }, []);

  useEffect(() => {
    if (serverData) {
      const newChartData = {
        labels: serverData[0].data.map(entry => entry.time),
        datasets: serverData.map(entry => ({
          label: nameMap[entry.type],
          data: entry.data.map(entry => entry.visitors),
          nodes: entry.data.map(entry => entry.nodes),
          borderColor: colorMap[entry.type],
          hidden: hiddenMap[entry.type],
          cubicInterpolationMode: 'monotone',
          tension: 0.1,
          hoverRadius: 10,
          hitRadius: 30,
          radius: 1,
        }))
      };
      setChartData(newChartData);
    }
  }, [serverData]);

  const xLabels = useMemo(() => chartData?.labels, [chartData]);
  const annotations = useAnnotations(xLabels, currentVisitors);

  const elementSize = useCanvas();

  return (
    <div className="app">
      {chartData &&
        <>
          <Line
            className='chart'
            style={{ width: elementSize.width, height: elementSize.height }}
            data={chartData}
            options={{
              color: '#EEEEEE',
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  ticks: {
                    color: '#EEEEEE',
                    callback: function (val, index) {
                      return index % 3 === 0 ? this.getLabelForValue(val) : null;
                    },
                  },
                  grid: {
                    color: function (context) {
                      if (context.tick.label.endsWith('00')) {
                        return '#333333'
                      } else {
                        return null;
                      }
                    },
                  }
                },
                y: {
                  ticks: {
                    color: '#EEEEEE',
                    beginAtZero: true,
                    callback: function (val, index) {
                      const value = this.getLabelForValue(val).replace(',', '.');
                      if (value.includes('.') && !value.endsWith('5')) {
                        return null;
                      } else {
                        return value;
                      }
                    },
                  },
                  grid: {
                    color: function (context) {
                      if (!context.tick.label.includes('.')) {
                        return '#333333'
                      } else {
                        return null;
                      }
                    },
                  },
                }
              },
              plugins: {
                title: {
                  display: true,
                  text: 'Gymbesökare',
                  font: { size: 16 },
                  color: '#EEEEEE',
                  align: 'start'
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const lineLabel = context.dataset.label;
                      const yValue = context.parsed.y;
                      const nodes = context.dataset.nodes[context.dataIndex];
                      const label = `${lineLabel}: ${yValue} - data från ${nodes} ${nodes === 1 ? 'dag' : 'dagar'}`;
                      return label;
                    }
                  }
                },
                annotation: {
                  animations: null,
                  annotations: annotations,
                  font: { size: 12 }
                },
              }
            }}
          />
        </>
      }
    </div>
  );
}

export default App;
