import { useEffect, useMemo, useState } from 'react';

const useAnnotations = (timeLabels, currentVisitors) => {
    const [currentTime, setCurrentTime] = useState();

    const annotations = useMemo(() => {
        const annotations = [];
        if (timeLabels && currentTime) {
            let latestPassedIndex = -1;

            for (let i = 0; i < timeLabels.length; i++) {
                if (timeLabels[i] <= currentTime) {
                    latestPassedIndex = i;
                } else {
                    break;
                }
            }
            if (latestPassedIndex !== -1) {
                annotations.push(
                    {
                        type: 'line',
                        borderColor: '#AAAAAA',
                        borderDash: [4, 4],
                        borderWidth: 1,
                        scaleID: 'x',
                        value: latestPassedIndex
                    }
                );
            }
        }

        if (currentVisitors) {
            annotations.push(
                {
                    type: 'line',
                    borderColor: '#AAAAAA',
                    borderDash: [4, 4],
                    borderWidth: 1,
                    scaleID: 'y',
                    value: currentVisitors
                }
            );
        }

        return annotations;
    }, [currentTime, currentVisitors, timeLabels]);

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const time = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
            setCurrentTime(time);
        }
        const timer = setInterval(updateTime, 1000);
        updateTime();
        return () => clearInterval(timer);
    }, []);

    return annotations;
}

export default useAnnotations;