import { useLayoutEffect, useState } from 'react';

const useCanvas = () => {
    const [elementSize, setElementSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useLayoutEffect(() => {
        const updateSize = () => {
            setElementSize({ width: window.innerWidth, height: window.innerHeight });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return elementSize;
}

export default useCanvas